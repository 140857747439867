import { useMapping } from "../../hooks/use-mapping";

export const ErrorLabel = ({
  error,
}: {
  error?: Error | null;
}) => {
  const label = useMapping(() => {
    if (!error) return null;

    return error.message;
  }, [ error ]);

  return <span>{label}</span>
}