import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Participant } from '../../data/participant-service';
import { useProfile } from '../../data/profile-service';
import { ProfilePortrait, ProfilePortraitSize } from '../images/profile-portrait';
import styles from './lists.module.scss';
import { useDonations } from '../../data/donation-service';
import { useMapping } from '../../hooks/use-mapping';
import { PriceLabel } from '../labels/price-label';
import { useFundraiser } from '../../data/fundraiser-service';
import { FundraiserBanner } from '../images/fundraiser-banner';
import { FundraiserProgressBar } from '../analytics/fundraiser-progress-bar';

const ParticipantListItem = ({
  participant,
  showBanner=true,
}: {
  participant: Participant;
  showBanner: boolean;
}) => {
  const profile = useProfile(participant.profileId);
  const fundraiser = useFundraiser(participant.fundraiserId);

  const banner = useMapping(() => {
    if (!showBanner) return null;
    if (!fundraiser.value) return null;
    return <FundraiserBanner fundraiser={fundraiser.value} />
  }, [ fundraiser.value, showBanner ]);

  const progressBar = useMapping(() => {
    if (!fundraiser.value) return <></>

    return <FundraiserProgressBar fundraiser={fundraiser.value} participantId={participant.id} />
  }, [ fundraiser.value ])

  return <Link to={`/fundraisers/${participant.fundraiserId}?participantId=${participant.id}`}>
    <li className={clsx(styles.participantRow, styles.clickable)}>
      <div className={clsx(styles.participantRow, styles.padding1)}>
        <div className={clsx(styles.flexRow, styles.gap2)}>
          <div className={clsx(styles.flex, styles.center)}>
            <div className={styles.bannerPortrait}>
              <ProfilePortrait profile={profile.value || undefined} size={ProfilePortraitSize.Medium} />
            </div>
          </div>
          <div className={clsx(styles.flex, styles.center, styles.padding1)}>
            <span className={styles.title}>{profile.value?.name}</span>
            <span>Participant In</span>
            <span className={styles.title}>{fundraiser.value?.title}</span>
          </div>
        </div>
        <div className={clsx(styles.flex, styles.margin1, styles.progressBar)}>
          {progressBar}
          {banner}
        </div>
      </div>
    </li>
  </Link>
}

export const ParticipantList = ({
  participants,
  showBanners=true,
}: {
  participants: Participant[],
  showBanners?: boolean;
}) => {
  const items = participants.map(participant=> {
    return <ParticipantListItem participant={participant} showBanner={showBanners} />
  });

  return <ul className={styles.list}>
    {items}
  </ul>
}