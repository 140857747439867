import clsx from "clsx";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useFundraisers } from "../../data/fundraiser-service";
import { FundraiserList } from "../lists/fundraiser-list";
import styles from './pages.module.scss';

export const FundraisersPage = () => {
  const fundraisers = useFundraisers();

  const [ searchParams, setSearchParams ] = useSearchParams();

  useEffect(() => {
    const participantId = searchParams.get('participant');
    if (participantId !== null) {
      sessionStorage.setItem('participant', participantId);
      setSearchParams(ps => {
        ps.delete('participant');
        return ps;
      });
    }

    const contactId = searchParams.get('contactId');
    if (contactId !== null) {
      sessionStorage.setItem('contactId', contactId);
      setSearchParams(ps => {
        ps.delete('contactId');
        return ps;
      });
    }
  }, [ searchParams ]);

  return <div className={styles.pageWrapper}>
    <div key='landing-page' className={clsx(styles.page)}>
      <div style={{ gap: '1rem', marginTop: '4rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <FundraiserList adminLinks={false} fundraisers={fundraisers.rows} />
      </div>
    </div>
  </div>
}