import axios, { AxiosInstance } from 'axios';
import React, { ReactNode } from 'react';

export type HttpState = {
  client: AxiosInstance;
  setHeaders: (x: any) => void;
}

export const defaultHttpClient = axios.create({
  baseURL: '/',
  paramsSerializer: {
    indexes: null,
  },
});

defaultHttpClient.interceptors.request.use((req: any) => {
  req.initialTime = new Date();
  return req;
});

defaultHttpClient.interceptors.response.use(resp => {
  const req = resp.config;
  const ti = (req as any).initialTime;
  const millis = (new Date().getTime()) - ti;
  const url = req.url;
  console.log("| http | %s %s | Status %d | time %sms |", req.method?.toUpperCase(), url, resp.status, millis);
  return resp;
});

export const HttpContext = React.createContext<HttpState>({
  client: null as any,
  setHeaders: (headers: any) => undefined,
});

export type HttpContextProps = {
  children?: ReactNode;
  client?: AxiosInstance;
};

export const HttpProvider = ({ 
  children,
  client=defaultHttpClient,
}: HttpContextProps) => {
  const setHeaders = (headers: any) => {
    for (const key of Object.keys(headers)) {
      const defaults = client.defaults.headers as any;
      defaults[key] = headers[key];
    }
  }

  return <HttpContext.Provider value={{
    client,
    setHeaders,
  }}>
    {children}
  </HttpContext.Provider>
}

export const withHttp = <Props,>(Component: any) => (props: Props) => {
  return <HttpProvider>
    <Component {...props} />
  </HttpProvider>
}

export const useHttp = () => React.useContext(HttpContext);
export default useHttp;