import clsx from 'clsx';
import { useState } from 'react';
import { Profile, useProfilesClient } from '../../data/profile-service';
import { useAuth } from '../../hooks/use-auth';
import { SimpleButton } from '../buttons/simple-button';
import styles from './forms.module.scss';

export const ProfileForm = ({
  onCreated,
}: {
  onCreated: (x: Profile) => void;
}) => {
  const profilesClient = useProfilesClient();
  const auth = useAuth();
  const [ error, setError ] = useState<Error | null>(null);

  const onSubmit = async (e: any) => {
    setError(null);
    const targets = e.target.elements;

    try {
      const fundraiser = await profilesClient.create({
        name: targets.name.value,
        accountId: auth.value?.accountId || '',
      });

      // Clear the inputs.
      for (const target of targets) {
        target.value = '';
      }
      onCreated(fundraiser);
    }
    catch (err) {
      setError(err as Error);
    }

    return true;
  }

  return <div className={styles.flatForm}>
    <h1>Profile Setup</h1>
    <span>
      {error?.message || ''}
    </span>
    <form className={clsx(styles.column, styles.fullWidth)} action="javascript:void(0);" onSubmit={onSubmit}>
      <label>Name</label>
      <input autoFocus={true} tabIndex={0} name="name" />

      <div className={clsx(styles.flex, styles.gap2)}>
        <SimpleButton>Submit</SimpleButton>
      </div>
    </form>
  </div>
}