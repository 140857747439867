import { AxiosInstance } from "axios";
import { EventDelegate } from "../delegate";
import { defaultHttpClient } from "../hooks/use-http";
import { AsyncArgs, useAsync } from "../hooks/use-async";
import { useEffect } from "react";

export type Participant = {
  id: string;
  profileId: string;
  fundraiserId: string;
  readonly totalContacts?: number;
  readonly totalRaised?: number;
}

export type ParticipantInput = {
  fundraiserId: string;
  profileId: string;
}

export type ParticipantQueryParams = {
  profileId?: string;
  fundraiserId?: string;
}

export type ParticipantPage = {
  rows: Participant[];
}

export class ParticipantsClient {
  onCreated = new EventDelegate<Participant>();
  onUpdated = new EventDelegate<Participant>();

  constructor(
    private client: AxiosInstance,
  ) { }

  create = async (input: ParticipantInput) => {
    const resp = await this.client.post('/api/v1/participants', input);
    this.onCreated.trigger(resp.data);
    return resp.data;
  }

  find = async (query?: ParticipantQueryParams): Promise<ParticipantPage> => {
    const resp = await this.client.get('/api/v1/participants', {
      params: query,
    });
    return resp.data;
  }

  get = async (participantId: string) => {
    const resp = await this.client.get(`/api/v1/participants/${participantId}`);
    return resp.data;
  }
}

const profilesClient = new ParticipantsClient(defaultHttpClient);

export const useParticipantsClient = () => {
  return profilesClient;
}

export const useParticipants = <A,>(query?: ParticipantQueryParams, asyncArgs?: AsyncArgs<A>) => {
  const profilesClient = useParticipantsClient();
  const task = useAsync(async () => {
    return await profilesClient.find(query);
  }, [ JSON.stringify(query) ], asyncArgs);

  useEffect(() => {
    return profilesClient.onCreated.listen(() => {
      task.trigger();
    });
  }, [ ]);

  return task;
}

export const useParticipant = (profileId: string) => {
  const profilesClient = useParticipantsClient();
  const task = useAsync(async () => {
    return await profilesClient.get(profileId);
  }, [ profileId ]);

  return task;
}