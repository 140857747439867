import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useFundraiserInvitesClient } from '../../data/fundraiser-invites-client';
import { Fundraiser, useFundraiser } from '../../data/fundraiser-service';
import { Profile, useProfilesClient } from '../../data/profile-service';
import { useAuth } from '../../hooks/use-auth';
import { useHttp } from '../../hooks/use-http';
import { useMapping } from '../../hooks/use-mapping';
import { SimpleButton } from '../buttons/simple-button';
import { ErrorLabel } from '../labels/error-label';
import styles from './forms.module.scss';
import { ProfileForm } from './profile-form';

export const AcceptParticipantForm = ({
  fundraiser,
  inviteId,
  profileId,
  onClose,
}: {
  fundraiser: Fundraiser;
  inviteId: string;
  profileId: string;
  onClose: () => void;
}) => {
  const http = useHttp();
  const fundraiserInvitesClient = useFundraiserInvitesClient();
  const nav = useNavigate();
  const [ error, setError ] = useState<Error | null>(null);

  const fundraiserInfo = useMapping(() => {
    if (fundraiser.bannerSrc) return <>
      <h4>{fundraiser.title}</h4>
      <img style={{ maxWidth: '30rem', width: '50%', height: 'auto', borderRadius: '1rem', overflow: 'hidden' }} src={`/api/v1/fundraisers/${fundraiser.id}/banner`} />
    </>

    return <>
      <h4>{fundraiser.title}</h4>
    </>
  }, [ fundraiser ]);

  const submit = async (e: any) => {
    setError(null);

    try {
      await fundraiserInvitesClient.accept({
        inviteId,
        profileId,
      })
      http.client.delete('/api/v1/participant-invite-tokens');

      nav('/');
      if (window.location.pathname === '/' || window.location.pathname === '') {
        onClose();
      }
    }
    catch (err) {
      setError(err as Error);
    }

    return true;
  }

  const handleCancel = (e: any) => {
    localStorage.removeItem('inviteLink');
    http.client.delete('/api/v1/participant-invite-tokens');

    e.preventDefault();
    onClose();
    return false;
  }

  return useMapping(() => {
    return <div className={clsx(styles.form, styles.flex, styles.gap2, styles.spaceBetween)}>
      <h4>Accept Participant Invite?</h4>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        {fundraiserInfo}
      </div>
      <form className={styles.fullWidth} action="javascript:void(0);" onSubmit={submit}>
        <div className={clsx(styles.flex, styles.gap2, styles.spaceBetween, styles.reverseRow)}>
          <SimpleButton type='submit'>Accept</SimpleButton>
          <SimpleButton type="button" onClick={handleCancel}>Reject</SimpleButton>
        </div>
      </form>
    </div>
  }, [ error, inviteId, fundraiser ]);
}

const ParticipantSignupForm = ({
  fundraiserId,
  inviteId,
  onClose,
}: {
  fundraiserId: string;
  inviteId: string;
  onClose: () => void;
}) => {
  const http = useHttp();
  const navigate = useNavigate();
  const [ error, setError ] = useState<Error | null>(null);

  const submit = async (name: string, username: string, password: string) => {
    yup.string().required('Full name is required')
      .validateSync(name);
    yup.string().required('Username required')
      .min(4, 'Username must be at least 4 characters')
      .validateSync(username);
    yup.string().required('Password required')
      .min(8, 'Password must be at least 8 characters')
      .validateSync(password);

    await http.client.post('/api/v1/participant-signup', {
      inviteId,
      name,
      username,
      password,
    });

    await http.client.delete('/api/v1/participant-invite-tokens');
    window.location.reload();
    return true;
  }

  const onSubmit = async (e: any) => {
    const targets = e.target.elements;

    await submit(
      targets.name.value?.trim(),
      targets.username.value?.trim(),
      targets.password.value?.trim(),
    )
      .catch(setError);
  }

  const onCancel = () => onClose();

  return <div style={{ padding: '1rem' }}>
    <form className={clsx(styles.form, styles.gap1)} action="javascript:void(0);" onSubmit={onSubmit}>
      <h3>Create a login for the fundraiser.</h3>
      
      <label>Full Name</label>
      <input autoFocus tabIndex={0} name="name" required />
      <label>Login Name</label>
      <input name="username" required />
      <label>Password (Must be at least 8 characters long)</label>
      <input type="password" name="password" required />

      <ErrorLabel error={error} />
      <div className={clsx(styles.flex, styles.gap2, styles.spaceBetween, styles.reverseRow)}>
        <SimpleButton type="submit">Submit</SimpleButton>
        <SimpleButton type="button" onClick={onCancel}>Cancel</SimpleButton>
      </div>
    </form>
  </div>
}

const ParticipantSigninForm = ({
  fundraiserId,
  inviteId,
  onClose,
}: {
  fundraiserId: string;
  inviteId: string;
  onClose: () => void;
}) => {
  const http = useHttp();
  const [ error, setError ] = useState<Error | null>(null);

  const submit = async (username: string, password: string) => {
    yup.string().required('Username required')
      .validateSync(username);
    yup.string().required('Password required')
      .validateSync(password);

    await http.client.post('/api/v1/participant-login', {
      inviteId,
      username,
      password,
    }).catch(err => {
      const resp = err.response;
      if (!resp) return Promise.reject(err);

      const body = resp.data;
      if ('issue' in body) {
        return Promise.reject(new Error(body.issue));
      }

      return Promise.reject(new Error('bam'));
    });

    await http.client.delete('/api/v1/participant-invite-tokens');
    window.location.reload();
    return true;
  }

  const onSubmit = async (e: any) => {
    const targets = e.target.elements;

    await submit(
      targets.username.value?.trim(),
      targets.password.value?.trim(),
    )
      .catch(setError);
  }

  const onCancel = () => onClose();

  return <div style={{ padding: '1rem' }}>
    <form className={clsx(styles.form, styles.gap1)} action="javascript:void(0);" onSubmit={onSubmit}>
      <h3>Log in as a participant.</h3>
      
      <label>Login Name</label>
      <input autoFocus={true} tabIndex={0} name="username" required />
      <label>Password</label>
      <input type="password" name="password" required />

      <ErrorLabel error={error} />
      <div className={clsx(styles.flex, styles.gap2, styles.spaceBetween, styles.reverseRow)}>
        <SimpleButton type="submit">Submit</SimpleButton>
        <SimpleButton type="button" onClick={onCancel}>Cancel</SimpleButton>
      </div>
    </form>
  </div>
}

export const ConfirmParticipantForm = ({
  fundraiserId,
  inviteId,
  onClose,
}: {
  fundraiserId: string;
  inviteId: string;
  onClose: () => void;
}) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const profilesClient = useProfilesClient();
  const fundraiser = useFundraiser(fundraiserId);

  const [ error, setError ] = useState<string | null>(null);
  const [ profile, setProfile ] = useState<Profile | null | 'loading'>('loading');
  const [ signup, setSignup ] = useState(false);
  const [ signin, setSignin ] = useState(false);

  useEffect(() => {
    if (auth.isLoading) return;

    if (!auth.value) return;
    else {
      profilesClient.find({
        accountId: auth.value.accountId,
        limit: 1,
      })
      .then(page => {
        if (page.rows.length === 0) setProfile(null);
        else setProfile(page.rows[0]);
      })
      .catch(err => {
        alert(err);
      })
    }
  }, [ auth.value ])

  return useMapping(() => {
    if (error !== null) return <span>{error}</span>
    if (signup) return <ParticipantSignupForm 
      fundraiserId={fundraiserId}
      inviteId={inviteId}
      onClose={() => setSignup(false)}
    />
    if (signin) return <ParticipantSigninForm
      fundraiserId={fundraiserId}
      inviteId={inviteId}
      onClose={() => setSignup(false)}
    />

    if (!auth.value) return <div className={clsx(styles.form, styles.gap2)}>
      <span>You have a pending invite.</span>
      <span>Please hit the sign up button to accept.</span>
      <div className={clsx(styles.flex, styles.gap2, styles.spaceBetween)}>
        <SimpleButton small onClick={() => setSignup(true)}>Sign Up</SimpleButton>
        <SimpleButton small onClick={() => setSignin(true)}>Sign In</SimpleButton>
      </div>
    </div>
    if (auth.isLoading) return <></>
    if (fundraiser.loading) return <></>
    if (fundraiser.error) return <span>{error}</span>
    if (profile === 'loading') return <></>
    if (profile === null) return <ProfileForm onCreated={setProfile} />

    if (auth.value?.subject?.startsWith('qwik|')) {
      onClose();
      navigate(`/`, { replace: true });
      // navigate(`/fundraisers/${fundraiserId}`);
      return <></>
    }

    return <AcceptParticipantForm
      fundraiser={fundraiser.value}
      inviteId={inviteId}
      profileId={profile.id}
      onClose={onClose}
    />
  }, [ JSON.stringify(auth.value), profile, error, fundraiser.value, signup, signin ])
}