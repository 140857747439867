import { Auth, useAuth } from '../../hooks/use-auth';
import { useMapping } from '../../hooks/use-mapping';
import { usePreferences } from '../../hooks/use-preferences';
import { ProfileForm } from '../forms/profile-form';
import { ProfilePortraitInput } from '../inputs/profile-portrait-input';
import styles from './sections.module.scss';

const SetupProfile = () => {
  return <section className={styles.section}>
  </section>
}

const ProfileLoader = ({
  auth,
}: {
  auth: Auth; 
}) => {
  const [ preferences ] = usePreferences();
  const portraitMessage = useMapping(() => {
    if (preferences.value?.profile?.portraitSrc) return null;

    return <span style={{ marginTop: '-5rem', position: 'absolute' }}>
      Upload your photo here
    </span>
  }, [ preferences.value?.profile?.portraitSrc ]);

  const view = useMapping(() => {
    if (!preferences.value) return <></>
    if (!preferences.value?.profile) 
      return <SetupProfile />

    const profile = preferences.value.profile;

    return <section className={styles.section}>
      <div className={styles.profileHeader}>
        <ProfilePortraitInput profile={preferences.value.profile} />
        {portraitMessage}
        <h4>
          {profile.name}
        </h4>
      </div>
    </section>
  }, [ preferences.value, portraitMessage ]);

  return <section className={styles.section}>
    {view}
  </section>
}

export const EditProfileSection = () => {
  const auth = useAuth();
  return useMapping(() => {
    if (!auth.value) return <></>

    return <ProfileLoader auth={auth.value} />
  }, [ JSON.stringify(auth.value) ]);

}