import { Link, useSearchParams } from "react-router-dom";
import { useContributions } from "../../data/contribution-service";
import { Fundraiser, FundraiserStatus, useFundraiserPermissions } from "../../data/fundraiser-service";
import { useAccountProfile, useProfiles, useProfilesClient } from "../../data/profile-service";
import { useAuth, useAuthValue } from "../../hooks/use-auth";
import { useMapping } from "../../hooks/use-mapping";
import { FundraiserProgressBar } from "../analytics/fundraiser-progress-bar";
import { SimpleButton } from "../buttons/simple-button";
import { Icon } from "../icons/icon";
import { ProfilePortrait, ProfilePortraitSize } from "../images/profile-portrait";
import { ContributionList } from "../lists/contributions-list";
import styles from './headers.module.scss';
import clsx from 'clsx';
import { DonateButton } from "../buttons/donate-button";
import { useDonations } from "../../data/donation-service";
import { DonationList } from "../lists/donation-list";
import { ShareFundraiserIcon } from "../icons/share-fundraiser-icon";
import { useParticipantsClient } from "../../data/participant-service";
import { useAsync } from "../../hooks/use-async";

const ParticipantPortrait = ({
  participantId,
  fundraiser,
}: {
  participantId: string;
  fundraiser: Fundraiser;
}) => {
  const profilesClient = useProfilesClient();
  const participantsClient = useParticipantsClient();
  const profile = useAsync(async () => {
    const participant = await participantsClient.get(participantId);
    return await profilesClient.get(participant.profileId);
  });

  return <div className={clsx(styles.topInfo, styles.margin1, styles.gap2)}>
    <ProfilePortrait size={ProfilePortraitSize.Medium} profile={profile.value || undefined} />
    <div>
      <h6>{profile.value?.name}, participating in</h6>
      <h2>{fundraiser.title}</h2>
    </div>
  </div>
}

export const FundraiserPortrait = ({
  fundraiser,
}: {
  fundraiser: Fundraiser;
}) => {
  const profile = useAccountProfile(fundraiser.accountId);
  return <div className={clsx(styles.topInfo, styles.margin1, styles.gap2)}>
    <ProfilePortrait size={ProfilePortraitSize.Medium} profile={profile.value || undefined} />
    <div>
      <h4>{fundraiser.title}</h4>
    </div>
  </div>
}

export const FundraiserHeader = ({
  fundraiser,
}: {
  fundraiser: Fundraiser;
}) => {
  const auth = useAuth();
  const [ searchParams ] = useSearchParams();

  const participantId = useMapping(() => {
    return searchParams.get('participant');
  }, [ searchParams ]);

  const donations = useDonations({
    fundraiserId: fundraiser.id,
    participantId: participantId || undefined,
  });
  const permissions = useFundraiserPermissions({
    fundraiserId: fundraiser.id,
    accountId: auth.value?.accountId,
  });

  const portrait = useMapping(() => {
    if (participantId) return <ParticipantPortrait fundraiser={fundraiser} participantId={participantId} />
    return <FundraiserPortrait fundraiser={fundraiser} />
  }, [ fundraiser.accountId, participantId ])

  // const banner = useMapping(() => {
  //   if (!fundraiser.bannerSrc) return <></>
  //   return <img src={`/api/v1/fundraisers/${fundraiser.id}/banner`} />
  // }, [ fundraiser.bannerSrc ]);

  const interactions = useMapping(() => {
    const buttons: JSX.Element[] = [];

    const access = permissions.value?.rows?.map(x => x.access) || [];
    if (access.includes('write')) {
      buttons.push(
        <Link to={`/fundraisers/${fundraiser.id}/settings`}>
          <Icon icon='settings' />
        </Link>
      );
    }

    return buttons;
  }, [ permissions.value ]);

  const status = useMapping(() => {
    const now = new Date();
    const startsAt = fundraiser.startsAt ? new Date(fundraiser.startsAt) : null;
    const endsAt = fundraiser.endsAt ? new Date(fundraiser.endsAt) : null;

    if (fundraiser.status === FundraiserStatus.Closed) {
      return <div className={clsx(styles.flex, styles.center)}>
        <span>Fundraiser is Closed.</span>
      </div>
    }
    else if (startsAt === null || endsAt === null) {
      return <div className={clsx(styles.flex, styles.center, styles.padding1)}>
        <span>Sorry, fundraiser dates have not been specified. Please come back later.</span>
      </div>
    }
    else if (now < startsAt) {
      return <div className={clsx(styles.flex, styles.center)}>
        <span>This fundraiser starts on {startsAt.toLocaleDateString()}, at {startsAt.toLocaleTimeString()}</span>
      </div>
    }
    else if (now >= endsAt) {
      return <div className={clsx(styles.flex, styles.center)}>
        <span>This fundraiser ended on {endsAt.toLocaleDateString()}, at {endsAt.toLocaleTimeString()}</span>
      </div>
    }
    else if (fundraiser.status === FundraiserStatus.Open) {
      return <>
        <div className={styles.margin1}>
          <FundraiserProgressBar fundraiser={fundraiser} />
        </div>
        <div className={clsx(styles.flex, styles.padding1, styles.center)}>
          <span>This fundraiser ends on {endsAt.toLocaleDateString()}, at {endsAt.toLocaleTimeString()}</span>
        </div>
        <div className={clsx(styles.flex, styles.padding1, styles.center)}>
          <ShareFundraiserIcon fundraiserId={fundraiser.id} />
        </div>
      </>
    }
  }, [ fundraiser ]);

  return <header className={styles.header}>
    <div className={clsx(styles.infoWrapper)}>
      <div className={styles.info}>
        <div className={clsx(styles.flex, styles.spaceBetween, styles.margin1)}>
          <div>
            {interactions}
          </div>
          <div>
            <DonateButton fundraiserId={fundraiser.id} />
          </div>
        </div>
        {portrait}
        <div className={styles.margin1}>
          <p className={styles.description}>{fundraiser.description}</p>
        </div>
        {status}
      </div>
      <div className={styles.contributions}>
        <DonationList donations={donations.value?.rows || []} />
      </div>
    </div>
  </header>
}
